var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{ref:"card",staticClass:"platform-card",class:{
     'no-padding': _vm.noPadding,
     'no-lines': _vm.noLines,
     'actions-hidden': _vm.hiddenActions,
     'no-overflow': _vm.noOverflow,
     'card-type-blue': _vm.blue,
     'not-shown': _vm.internalNotShown,
     'no-scroll': _vm.noScroll,
     expanded: _vm.isExpanded,
     'no-border-radius': _vm.isFullscreen,
     'is-expanding': _vm.isExpanding,
     tablet: _vm.isTablet,
     collapsed: _vm.isCollapsed
   },style:({
     height: !!_vm.height ? _vm.height : 'auto',
     maxWidth: !!_vm.width ? _vm.width : '100%',
     width: !!_vm.width ? _vm.width : '100%',
     left: _vm.x,
     top: _vm.y,
     position: _vm.isPositioned ? 'absolute' : 'static'
   })},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.hasHeader),expression:"hasHeader"}],ref:"header",staticClass:"header"},[_vm._t("header")],2),_vm._v(" "),_c('div',{ref:_vm.contentOverflow ? 'content' : '',staticClass:"card-content scroll-area",style:({
       height: _vm.isCollapsed ? 0 : _vm.contentHeight || undefined
     })},[_vm._t("default")],2),_vm._v(" "),(_vm.hasActions)?_c('div',{ref:"actions",staticClass:"actions",class:{
       'space-between': _vm.actionSpaceBetween
     }},[_vm._t("actions")],2):_vm._e(),_vm._v(" "),(_vm.hasFooter)?_c('div',{ref:"footer",staticClass:"footer"},[_vm._t("footer")],2):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }