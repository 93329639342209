<template>
  <div class="circular-loader-wrapper">
    <div class="content" :class="{ active: internalLoaded }">
      <slot></slot>
    </div>
    <div
      class="loader-wrapper"
      :class="{ active: !internalLoaded, 'full-height': fullHeight }"
    >
      <!-- <circural-loader /> -->
      <loading-text-dots color="black" />
    </div>
  </div>
</template>

<script>
// import CircurarLoader from "./CircularLoader";
import LoadingTextDots from "../loaders/LoadingTextDots";

export default {
  components: {
    // "circural-loader": CircurarLoader,
    "loading-text-dots": LoadingTextDots
  },

  props: {
    loaded: {
      type: Boolean,
      default: false
    },
    fullHeight: {
      type: Boolean,
      default: false
    }
  },

  data: () => ({
    internalLoaded: false,
    timer: null
  }),

  watch: {
    loaded: {
      immediate: true,
      handler(val) {
        if (val) {
          this.timer = setTimeout(() => {
            this.internalLoaded = true;
          }, 200);
        } else {
          if (this.timer) {
            clearTimeout(this.timer);
            this.internalLoaded = false;
          }
        }
      }
    }
  },

  created() {
    this.internalLoaded = this.loaded;
  }
};
</script>

<style lang="sass" scoped>
.circular-loader-wrapper
  width: 100%
  height: 100%
  position: relative
  .loader-wrapper
    position: absolute
    top: 0
    left: 0
    display: flex
    align-items: center
    justify-content: center
    height: 100%
    width: 100%

  .content, .loader-wrapper
    transition: opacity 0.5s
    pointer-events: none
    opacity: 0
    height: 100%
  .loader-wrapper
    transition-duration: 1s
  .content.active
    opacity: 1
    pointer-events: all
  .loader-wrapper.active
    opacity: 1
    pointer-events: all
</style>
