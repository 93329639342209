<template>
  <div
    v-ripple="10"
    class="menu-search-result-item"
    :class="{ mobile: isMobile, selected }"
    @click="pick"
  >
    <div class="icon"><i :class="icon"></i></div>
    <span class="name">{{ item.text }}</span>
    <!-- <div class="arrow">
      <i class="mi-chevron-right"></i>
    </div> -->
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  props: {
    item: {
      type: Object,
      default: () => ({})
    },
    selected: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    ...mapGetters({
      isMobile: "app_store/isMobile"
    }),
    icon() {
      if (this.item.type === "security") return "la la-file-alt";
      if (this.item.type === "member") return "la la-user";
      return "";
    }
  },

  methods: {
    pick() {
      if (this.item.type === "member") {
        this.$router.push({ path: `/member-new/?m=${this.item.id}` });
      } else {
        this.$router.push({ path: `/security-new/?s=${this.item.id}` });
      }
    }
  }
};
</script>

<style lang="sass" scoped>
.menu-search-result-item
  display: grid
  grid-template-columns: 16px 1fr 14px
  grid-gap: 6px
  padding: calc(var(--dashboard-gap) / 50) 8px
  cursor: pointer
  border-radius: 3px
  transition: all 0.2s ease-in-out
  margin-top: calc(var(--dashboard-gap) / 2)
  &.mobile
    padding: 12px
  &:hover:not(.mobile), &.selected
    background-color: var(--global-select-hovered)
  .icon
    display: flex
    align-items: center
    i
      font-size: var(--font-size-small)
      color: rgba(0,0,0,.7)
  .name
    font-size: var(--font-size-small)
    color: rgba(0,0,0,1)
    font-weight: 400

  .arrow
    display: flex
    align-items: center
    justify-content: flex-end
    i
      font-size: var(--font-size-small)
      color: rgba(0,0,0,.5)
</style>
